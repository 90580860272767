<template>
<div class="topHeader">
  <div style="text-align: center;width: 20%;"><img src="@/assets/logo.png" style="height: 55px;margin-top: 6px"></div>
  <img src="@/assets/menu.svg" style="width: 30px;text-align: right;cursor: pointer" @click="busData()">
</div>
</template>

<script>
import bus from '@/bus.js'

export default {
  name: "topHeader",
  data(){
    return{
      activeIndex:'1',
      lang:'简体中文',
      showHeader:false
    }
  },
  created() {
    if(sessionStorage.getItem('index')){
      this.activeIndex=sessionStorage.getItem('index')
    }
    bus.$on('cancel',data=>{
      this.showHeader=data
    })
  },
  methods:{
    handleSelect(){

    },
    changeLang(val){
      console.log(val);
      this.lang=val
    },
    toPage(val){
      this.activeIndex=val
      if(val==='1'&& this.$route.path !== '/home'){
        this.$router.push('/home')
      }else if(val==='2'&& this.$route.path !== '/about'){
        this.$router.push('/about')
      }else if(val==='3'&& this.$route.path !== '/introduce'){
        this.$router.push('/introduce')
      }else if(val==='4'&& this.$route.path !== '/whitePaper'){
        this.$router.push('/whitePaper')
      }else if(val==='5'&& this.$route.path !== '/login'){
        this.$router.push('/login')
      }
      sessionStorage.setItem('index',val)
    },
    busData(){
      this.showHeader=!this.showHeader
      bus.$emit('showDialog',this.showHeader)
    }
  }
}
</script>

<style scoped>
.topHeader{
  width: 95%;
  margin: 0 auto 2px auto;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  /*justify-content: space-between;*/
  transition: all 2s ease;
  background: transparent;
}
.headerTitle {
  margin: 0 3%;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.headerTitle_active{
  margin: 0 3%;
  color: #ffe693;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.headerTitle:hover{
  color: #ffe693;
}
</style>