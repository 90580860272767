import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: 'TIẾNG VIỆT',
    activeIndex:'1'
  },
  mutations: {
    setLanguage(state, newLanguage) {
      state.language = newLanguage;
    },
    setIndex(state,newIndex){
      state.activeIndex=newIndex;
    }
  },
  actions: {
    changeLanguage({ commit }, newLanguage) {
      commit('setLanguage', newLanguage);
    },
    changeIndex({commit},newIndex){
      commit('setIndex',newIndex)
    }
  }
});

