<template>
  <div class="main-class" style="display: flex; align-items: center; user-select: none;">
    <div v-for="(item, index) in numberToArray" :key="index"
         :class="['number', { sign: ignoreStr.includes(item) }, {sign2: item === '1'}]"
         :style="{...numStyle, ...(ignoreStr.includes(item) ? customSignStyle: {})}">
      <div class="scroll-container" :style="{ ...animateStyle(item) }">
        <div v-for="(v, i) in numberBox" :key="i" class="scroll-single" :style="{ color: color , height: `${h}px`}">
          {{ v }}
        </div>
      </div>
    </div>
    <div :style="{...unitStyle , fontSize: `${h}px`}">
      {{ unit }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ScrollNumber",
  props: {
    customSignStyle: {
      type: Object,
      default: () => {
        return {}
      }
    },
    unit: {
      type: String,
      default: ''
    },
    unitMargin: {
      type: String,
      default: '0 0 0 5px'
    },
    unitStyle: {
      type: Object,
      default: () => {
        return {
          margin: '0 0 0 5px'
        }
      }
    },
    number: {
      type: [Number, String],
      required: true,
      default: 0
    },
    h: {
      type: Number, // 数字高度
      default: 24
    },
    color: {
      type: [String],
      default: '#000'
    },
    customStyle: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    number() {
      this.numberToArray = this.number.toString().split('')
    }
  },
  data() {
    return {
      getBindValue: undefined,
      numberBox: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','],
      symbolBox: ['.', ':', ' ', ','],
      ignoreStr: ['.', ' ', '-', ':', ',', '1'],
      numberToArray: []
    }
  },
  created() {
    this.numberToArray = this.number.toString().replaceAll(/\d/g, "0").split("")
  },
  mounted() {
    let lady = 100
    setTimeout(() => {
      this.$nextTick(() => {
        this.numberToArray = this.number.toString().split('')
      })
    }, lady)
  },
  methods: {
    animateStyle(item) {
      const index = item.trim().length === 0 ? 9999 : this.numberBox.findIndex((i) => item === i)
      return {transform: `translate(0,-${index * this.h}px)`}
    }
  }
  ,
  computed: {
    numStyle() {
      return {
        ...this.customStyle,
        height: `${this.h}px`,
        fontSize: `${this.h}px`,
      }
    }
    ,
    timingFlag() {
      return this.$store.state.timingFlag
    }
  }
}
</script>

<style scoped>
.main-class {
  font-family: 'Manrope', "Source H", sans-serif;
}

.number {
  overflow: hidden;
}

.number.sign {
  width: 12px;
  margin: 0 -2.5px !important;
}

.number.sign2 {
  width: 12px;
  margin: 0 -1px !important;
}

.scroll-container {
  display: flex;
  flex-direction: column;
  transform: translate(0, 0);
  transition: all 1.2s;
}

.scroll-single {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 1;
  overflow: hidden;
}
</style>
