<template>
<div class="bottom">
  <div class="declare">
    <div style="width: 80%;margin: 0 auto;padding: 12px 0;line-height: 25px;">
      <img src="@/assets/danger.svg" style="width: 12px;margin-right: 2px;margin-top: 4px">
      <span style="color: #d60f2a">Tuyên bố miễn trách nhiệm：</span>Tất cả nội dung được cung cấp trên trang web này,
      siêu liên kết, ứng dụng liên quan, diễn đàn, blog và các tài khoản truyền thông khác đều có nguồn gốc
      từ nền tảng của bên thứ ba。Chúng tôi không đảm bảodưới bất kỳ hình thức nào về trang web và nội dung
      của nó，Tất cả dữ liệu và thông tin trên trang web chỉ nhằm mục đích tìm hiểu và nghiên cứu của người
      dùng,Không cấu thành bất kỳ khoản đầu tư nào、Đề xuất và bằng chứng của pháp luật và các lĩnh vực liên
      quan。Bạn nên sử dụng dữ liệu và nội dung một cách thận trọng và tự chịu mọi rủi ro phát sinh。Chúng tôi
      khuyến khíchTiến hành nghiên cứu của riêng bạn về nội dung、Xem xét, phân tích và xác minh。
    </div>
  </div>
  <div style="border-bottom: 1px solid #23262f">
    <div class="link_content">
      <div style="border-bottom: 1px solid #23262f">
        <div class="bottom_center">
          <div class="bottom_block">
            <div>
              <div style="margin: 0 0 10px 0"><img src="@/assets/logo.png" style="height: 58px;"></div>
              <div style="color: #FFFFFF;font-size: 16px;">
                Nếu có câu hỏi liên quan
              </div>
              <div style="color: #FFFFFF;font-size: 16px;margin: 10px 0 10px 0;line-height: 26px">
                Vui lòng liên hệ với chúng tôi qua địa chỉ email bên dưới👇
              </div>
              <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-message" style="color: #777E90;font-size: 30px"></i><div style="font-size: 16px;color: #ffffff;margin-left: 20px;margin-top: 5px">feixiaohaovn@gmail.com</div></div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;width: 75%;">
            <div class="bottom_block" style="justify-content: right;margin-left: 10%">
              <div>
                <div class="bottom_center_title">Hỗ trợ</div>
                <div class="bottom_center_smallTitle" @click="$router.push('/medium')">nộp đơn Ứng dụng truyền thông</div>
                <div class="bottom_center_smallTitle" @click="$router.push('/submitProject')">Nộp đơn dự án hạng mục</div>
                <div class="bottom_center_smallTitle" @click="$router.push('/exchange')">Gửi bao gồm trao đổi</div>
              </div>
            </div>
            <div class="bottom_block">
              <div>
                <div class="bottom_center_title">Dịch vụ người dùng</div>
                <div class="bottom_center_smallTitle" @click="toQuestion()">Câu hỏi thường gặp</div>
                <div class="bottom_center_smallTitle" @click="toSuggestion()">phản hồi</div>
              </div>
            </div>
            <div class="bottom_block">
              <div>
                <div class="bottom_center_title">Nhiều nội dung hơn</div>
                <div class="bottom_center_smallTitle" @click="toAbout()">giới thiệu về chúng tôi</div>
                <div class="bottom_center_smallTitle" @click="toDisclaimer()">tuyên bố miễn trách nhiệm</div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="bottom_center">-->
<!--        <div>-->
<!--          <div class="bottom_center_title">Hỗ trợ</div>-->
<!--          <div class="bottom_center_smallTitle">nộp đơn Ứng dụng truyền thông</div>-->
<!--          <div class="bottom_center_smallTitle">Nộp đơn dự án hạng mục</div>-->
<!--          <div class="bottom_center_smallTitle">Nộp đơn tỉ lệ hiệu quả giáo dục</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div class="bottom_center_title">Dịch vụ người dùng</div>-->
<!--          <div class="bottom_center_smallTitle">Câu hỏi thường gặp</div>-->
<!--          <div class="bottom_center_smallTitle">phản hồi</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div class="bottom_center_title">Nhiều nội dung hơn</div>-->
<!--          <div class="bottom_center_smallTitle" @click="$router.push('/about')">giới thiệu về chúng tôi</div>-->
<!--          <div class="bottom_center_smallTitle">tuyên bố miễn trách nhiệm</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
  <div class="cooperate_content">
    <div class="link"><a href="http://www.oicq88.com/" target="_blank" class="item">tên mạng</a></div>
    <div class="link"><a href="https://apr999.com/" target="_blank" class="item">defi So sánh thu nhập và khai thác</a></div>
    <div class="link"> <a href="http://www.qqbiaoqing.com/" target="_blank" class="item">BIỂU TƯỢNG CẢM XÚC</a></div>
    <div class="link"><a href="http://www.ip138.com/" target="_blank" class="item">truy vấn IP</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/ethereum/" target="_blank" class="item">Ethereum</a></div>
    <div class="link"><a href="http://www.liantu.com/" target="_blank" class="item">mã QR</a></div>
    <div class="link"><a href="http://www.shilian.com/" target="_blank" class="item">chuỗi tài chính thế giới</a></div>
    <div class="link"> <a href="https://www.btcfans.com/" target="_blank" class="item">fanfan</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/dogecoin/" target="_blank" class="item">Dogecoin</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/binance-coin/" target="_blank" class="item">BNB</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/tether/" target="_blank" class="item">Tether</a></div>
    <div class="link"><a href="https://www.fx678.com/" target="_blank" class="item">hội đồng mạng</a></div>
    <div class="link"><a href="https://www.yitaifang.com/" target="_blank" class="item">trình duyệt Ethereum</a></div>
    <div class="link"><a href="https://www.superoneapp.com/" target="_blank" class="item">Đề xuất trang web</a></div>
    <div class="link"><a href="https://www.qklw.com/" target="_blank" class="item">Mạng Blockchain QKLW</a></div>
    <div class="link"> <a href="https://dexnav.com/" target="_blank" class="item">Điều hướng Blockchain</a></div>
  </div>
</div>
</template>

<script>
export default {
  name: "bottomView",
  methods:{
    toAbout(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'1'}})
      }else {
        this.$store.commit('setIndex','1')
      }
    },
    toQuestion(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'4'}})
      }else {
        this.$store.commit('setIndex','4')
      }
    },
    toDisclaimer(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'3'}})
      }else {
        this.$store.commit('setIndex','3')
      }
    },
    toSuggestion(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'2'}})
      }else {
        this.$store.commit('setIndex','2')
      }
    }
  }
}
</script>

<style scoped lang="less">
.bottom_block{
  width: 25%;
  display: flex;
  //background: #4b28e5;
  justify-content: right;
}

.bottom_block:first-child{
  justify-content: left;
}


.declare {
  min-height: 70px;
  width: 100%;
  background: #32373b;
  color: #FFFFFF;
  font-size: 14px;
}

a{
  color: #B1B5C3;
  display: block;
  text-decoration: none;
}
.bottom {
  height: 470px;
  width: 100%;
  //background: #938da4;
  border-top: 1px solid #23262f;
}
//.link_content {
//  width: 80%;
//  margin: 0 auto;
//  display: flex;
//}
//.bottom_left{
//  width: 22%;
//  border-right: 1px solid #23262f;
//}
.bottom_center{
  width: 80%;
  //background: #5ebc7c;
  margin: 34px auto;
  display: flex;
  &_title{
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &_smallTitle{
    font-size: 16px;
    color: #B1B5C3;
    margin-bottom: 15px;
    cursor: pointer;
  }
  &_smallTitle:hover{
    color: #535ce2;
  }
}

//.bottom_right{
//  width: 22%;
//  border-left: 1px solid #23262f;
//}

.download {
  color: #FFFFFF;
  display: flex;
  padding:3px 20px;
  border: 1px solid #FFFFFF;
  border-radius: 2em;
  width: 100px;
  height: 26px;
  line-height: 26px;
  margin-bottom: 24px;
  cursor: pointer;

}
.download img{
  width: 16px;
}

.download_title{
  margin-left: 8px;
  font-size: 14px;
}

.cooperate_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
}

.link{
  line-height: 45px;
  margin: 0 10px;
  cursor: pointer;
}
</style>