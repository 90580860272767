<template>
  <div class="bottom">
    <div style="margin: 0 0 0 10px"><img src="@/assets/logo.png" style="height: 58px;"></div>
    <div class="bottom_headLine" @click="expand(0)">
      <div>免责声明</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="bottom_center" v-if="declare">
      <div class="bottom_center_smallTitle">
        <div style="width: 100%;margin: 0 auto;line-height: 25px;">
          <img src="@/assets/danger.svg" style="width: 12px;margin-right: 2px;margin-top: 4px">
          <span style="color: #d60f2a">免责声明：</span>本网站、超链接、相关应用程序、论坛、博客等媒体账户
          以及其他平台提供的所有内容均来源于第三方平台。我们对于网站及其
          内容不作任何类型的保证，网站所有区块链相关数据与资料仅供用户学习
          及研究之用，不构成任何投资、法律等其他领域的建议和依据。您需谨慎
          使用相关数据及内容，并自行承担所带来的一切风险。强烈建议您独自对
          内容进行研究、审查、分析和验证。
        </div>
      </div>
    </div>
    <div class="bottom_headLine" @click="expand(1)">
      <div>联系我们</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="bottom_left" v-if="linkOur">
      <div style="margin: 25px auto;">
        <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-phone-outline" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left: 15px;margin-top: 3px">13232323232</div></div>
        <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-message" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left:15px;margin-top: 3px">asfg@email.com</div></div>
        <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-chat-dot-square" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left:15px;margin-top: 3px">@13232323232</div></div>
        <div style="display: flex;"><i class="el-icon-location" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left:15px;margin-top: 3px">北京市xx区xx</div></div>
      </div>
      <div class="bottom_center_smallTitle">官方客服</div>
      <div class="bottom_center_smallTitle">项目/交易所收率</div>
      <div class="bottom_center_smallTitle">广告合作</div>
    </div>
    <div class="bottom_headLine" @click="expand(2)">
      <div>小号支持</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="bottom_center" v-if="support">
      <div class="bottom_center_smallTitle">媒体入驻申请</div>
      <div class="bottom_center_smallTitle">项目收录提交</div>
      <div class="bottom_center_smallTitle">教育所收率提交</div>
    </div>
    <div class="bottom_headLine" @click="expand(3)">
      <div>用户服务</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="bottom_center" v-if="servece">
      <div class="bottom_center_smallTitle">常见问题</div>
      <div class="bottom_center_smallTitle">意见反馈</div>
      <div class="bottom_center_smallTitle">官方验证通道</div>
    </div>
    <div class="bottom_headLine" @click="expand(4)">
      <div>更多内容</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="bottom_center" v-if="getMore">
      <div class="bottom_center_smallTitle">关于我们</div>
      <div class="bottom_center_smallTitle">免责声明</div>
      <div class="bottom_center_smallTitle">访问手机版</div>
    </div>
    <div class="bottom_headLine" @click="expand(5)">
      <div>客户端下载</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="bottom_right" v-if="download">
      <div>
        <div class="download">
          <img src="@/assets/bottom/IOS.svg">
          <div class="download_title">IOS下载</div>
        </div>
        <div class="download">
          <img src="@/assets/bottom/anz.svg">
          <div class="download_title">安卓下载</div>
        </div>
        <div class="download">
          <img src="@/assets/bottom/win.svg">
          <div class="download_title">Windows</div>
        </div>
        <div class="download">
          <img src="@/assets/bottom/mac.svg">
          <div class="download_title">Mac</div>
        </div>
      </div>
    </div>
    <div class="bottom_headLine" @click="expand(6)" style="margin: 30px auto 0 auto;padding: 0 0 20px 0">
      <div>友情链接</div>
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="cooperate_content" v-if="friendlyLink">
      <div class="link"><a href="http://www.oicq88.com/" target="_blank" class="item">网名</a></div>
      <div class="link"><a href="https://apr999.com/" target="_blank" class="item">defi挖矿收益对比</a></div>
      <div class="link"> <a href="http://www.qqbiaoqing.com/" target="_blank" class="item">表情</a></div>
      <div class="link"><a href="http://www.ip138.com/" target="_blank" class="item">IP查询</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/ethereum/" target="_blank" class="item">以太坊</a></div>
      <div class="link"><a href="http://www.liantu.com/" target="_blank" class="item">二维码</a></div>
      <div class="link"><a href="http://www.shilian.com/" target="_blank" class="item">世链财经</a></div>
      <div class="link"> <a href="https://www.btcfans.com/" target="_blank" class="item">比特范</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/dogecoin/" target="_blank" class="item">狗狗币</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/binance-coin/" target="_blank" class="item">BNB</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/tether/" target="_blank" class="item">泰达币</a></div>
      <div class="link"><a href="https://www.fx678.com/" target="_blank" class="item">汇通网</a></div>
      <div class="link"><a href="https://www.yitaifang.com/" target="_blank" class="item">以太坊浏览器</a></div>
      <div class="link"><a href="https://www.superoneapp.com/" target="_blank" class="item">网站推荐</a></div>
      <div class="link"><a href="https://www.qklw.com/" target="_blank" class="item">区块链网QKLW</a></div>
      <div class="link"> <a href="https://dexnav.com/" target="_blank" class="item">区块链导航</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomView",
  data(){
    return{
      declare:false,
      linkOur:false,
      support:false,
      servece:false,
      getMore:false,
      download:false,
      friendlyLink:false
    }
  },
  methods:{
    expand(val){
      if(val===0&&this.declare===false){
        this.declare=true
      }else if(val===1&&this.linkOur===false){
        this.linkOur=true
      }else if(val===2&&this.support===false){
        this.support=true
      }else if(val===3&&this.servece===false){
        this.servece=true
      }else if(val===4&&this.getMore===false){
        this.getMore=true
      }else if(val===5&&this.download===false){
        this.download=true
      }else if(val===6&&this.friendlyLink===false){
        this.friendlyLink=true
      }
      else (
            this.declare=false,
                this.linkOur=false,
                this.support=false,
                this.servece=false,
                this.getMore=false,
                this.download=false,
                this.friendlyLink=false
        )
    }
  }
}
</script>

<style scoped lang="less">
.bottom_headLine {
  width: 90%;
  margin: 30px auto;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: white;
}

a{
  color: #B1B5C3;
  display: block;
  text-decoration: none;
}

.bottom {
  height: auto;
  width: 100%;
  border-top: 1px solid #23262f;
}
.link_content {
  width: 90%;
  margin: 0 auto;
}
.bottom_left{
  width: 90%;
  margin: 0 auto;
}
.bottom_center{
  width: 90%;
  margin: 0 auto;
  &_title{
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &_smallTitle{
    font-size: 16px;
    color: #B1B5C3;
    margin-bottom: 15px;
    cursor: pointer;
  }
  &_smallTitle:hover{
    color: #535ce2;
  }
}

.bottom_right{
  width: 90%;
  margin: 0 auto;
}

.download {
  color: #FFFFFF;
  display: flex;
  padding:3px 20px;
  border: 1px solid #FFFFFF;
  border-radius: 2em;
  width: 100px;
  height: 26px;
  line-height: 26px;
  margin-bottom: 24px;
  cursor: pointer;

}
.download img{
  width: 16px;
}

.download_title{
  margin-left: 8px;
  font-size: 14px;
}

.cooperate_content {
  width: 90%;
  margin: 0 auto;
}

.link{
  line-height: 45px;
  cursor: pointer;
}
</style>