<template>
<div>
  <div v-if="$store.state.language==='简体中文'">
    <bottom_web v-if="screenWidth>600"></bottom_web>
    <bottom_h5 v-else></bottom_h5>
  </div>
  <div v-if="$store.state.language==='TIẾNG VIỆT'">
    <bottom_web_lang v-if="screenWidth>600"></bottom_web_lang>
    <bottom_h5_lang v-else></bottom_h5_lang>
  </div>
</div>
</template>

<script>
import bottom_web from "@/components/bottom_web.vue";
import bottom_h5 from "@/components/bottom_h5.vue";
import bottom_web_lang from "@/components/bottom_web_lang";
import bottom_h5_lang from "@/components/bottom_h5_lang.vue";

export default {
  name: "bottomView",
  components: {bottom_web,bottom_h5,bottom_web_lang,bottom_h5_lang},
  data(){
    return{
      screenWidth: null,
      alignCenter:'left',
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },

  watch: {
    screenWidth: {
      handler: function (val) {
        this.screenWidth=val
      },
      immediate: true,
      deep:true
    },
  }
}
</script>

<style scoped lang="less">

</style>