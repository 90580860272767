import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import(/* webpackChunkName: "about" */ '../views/introduce.vue')
  },
  {
    path: '/whitePaper',
    name: 'whitePaper',
    component: () => import(/* webpackChunkName: "about" */ '../views/whitePaper.vue')
  },
  {
    path: '/market',
    name: 'market',
    component: () => import(/* webpackChunkName: "about" */ '../views/market.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/topHeader',
    name: 'topHeader',
    component: () => import(/* webpackChunkName: "about" */ '../components/topHeader.vue')
  },
  {
    path: '/searchItem',
    name: 'searchItem',
    component: () => import(/* webpackChunkName: "about" */ '../views/searchItem.vue')
  },
  {
    path: '/newCurrency',
    name: 'newCurrency',
    component: () => import(/* webpackChunkName: "about" */ '../views/newCurrencyListed.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "about" */ '../views/information.vue')
  },
  {
    path: '/medium',
    name: 'medium',
    component: () => import(/* webpackChunkName: "about" */ '../views/medium.vue')
  },
  {
    path: '/submitProject',
    name: 'submitProject',
    component: () => import(/* webpackChunkName: "about" */ '../views/submitProject.vue')
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import(/* webpackChunkName: "about" */ '../views/exchange.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
