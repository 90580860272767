<template>
<div>
  <top-header_web v-if="screenWidth>600"></top-header_web>
  <top-header_h5 v-else></top-header_h5>
</div>
</template>

<script>
import topHeader_web from "@/components/topHeader_web.vue";
import topHeader_h5 from "@/components/topHeader_h5.vue";
export default {
  name: "topHeader",
  components: {topHeader_web,topHeader_h5},
  data(){
    return{
      screenWidth: null,
      alignCenter:'left'
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })
    }
  },

  watch: {
    screenWidth: {
      handler: function (val) {
        this.screenWidth=val
      },
      immediate: true,
      deep:true
    },
  }
}
</script>

<style scoped>

</style>