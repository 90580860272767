<template>
  <div class="home">
    <div class="searchPanel">
      <!--      <div class="searchPanel_price" style="color: white">{{coinPrice.name}}价格${{coinPrice.pice}}</div>-->
      <div class="searchPanel_title">全球领先的交易平台</div>
      <div class="searchPanel_indexInfo">
        <p>feixiaohaovn浏览器全新升级，实时更新各种资讯</p>
        <a href="#infoModule">立即查看</a>
      </div>
      <div style="width: 40%;margin: 0 auto;position: relative;text-align: center;">
        <input class="searchPanel_input" v-model="searchContent" placeholder="请输入用户账号">
        <div class="searchPanel_imgBlock" @click="searchAccount()">
          <img src="@/assets/search.svg" class="searchPanel_img"></img>
        </div>
      </div>
    </div>

    <div style="width: 100%;margin: 0 auto;padding: 30px 0;border-radius: 20px;position: relative" class="echarts_content">
      <div style="color: #FFFFFF;position: absolute;font-size: 20px;left: 8%">1SE=1VND</div>
      <div ref="chart" id="echarts" style="width: 100%;height:400px;margin: 0 auto;"></div>
    </div>

    <div class="homeContent_panel" v-loading="loading">
      <!-- 展示卡片 -->
      <div class="homeContent_panel_small" v-for="(item,index) in marketData" :key="index" @click="toMarket(chainList[index].chainShortName)">

        <div style="display: flex;justify-content:space-between;">
          <div>
            <img :src="coinImg[index].url" style="width: 38px">
            <div style="display: flex;align-items: flex-end;">
              <!-- 币种全称 -->
              <div class="homeContent_panel_small_title" style="font-size: 14px;color: white;margin-right: 16px">{{chainList[index].chainFullName}}</div>
              <!-- 币种简称 -->
              <div class="homeContent_panel_small_title">{{chainList[index].chainShortName}}</div>

            </div>
          </div>

          <div style="background: #5ebc7c;color: #FFFFFF;padding: 6px 10px;border-radius: 1.2em;margin-top: 10px;display: flex;justify-content: right;height: 20px">
            {{item.lastPrice}}
          </div>
        </div>

        <div class="homeContent_panel_small_title">
          <div>
            <ScrollNumber class="scroll-number-sub-title" color="" :unit="computedUnit(item.marketCap)" :customStyle="{margin: '0 -0.5px'}" :unitStyle="{margin: '0 0 0 1px'}" :number="formatPriceWithEnglishUnit(item.marketCap)" ></ScrollNumber>
          </div>
          <div style="font-size: 16px;color: #777E90">全网总市值</div>
        </div>
        <div class="homeContent_panel_small_title">
          <div>
            <ScrollNumber class="scroll-number-sub-title" color="" :unit="computedUnit(item.volume24h)" :customStyle="{margin: '0 -0.5px'}" :unitStyle="{margin: '0 0 0 1px'}" :number="formatPriceWithEnglishUnit(item.volume24h)" ></ScrollNumber>
          </div>
          <div style="font-size: 16px;color: #777E90">24h交易量</div>
        </div>
      </div>
    </div>

    <!-- 2、内容模块 -->
    <div class="homeContent">

      <div class="homeContent_title">币种排行模块</div>
      <div style="width: 80%;margin: 20px auto 0 auto;overflow-x: auto;display: block;padding: 25px 25px 10px 25px;" class="rank_content">
        <el-table
            :data="currencyList"
            style="width: 100%">
          <el-table-column
              label="序号"
              type="index"
              :index="indexMethod"
              width="80">
          </el-table-column>
          <el-table-column
              prop="name"
              label="币种"
              width="260">
            <template slot-scope="scope">
              <div style="display: flex;align-items: flex-end">
                <img :src="marketImg[scope.row.rank-1].url" style="width: 20px;margin: 0 10px">
                <div style="font-size: 18px;">{{scope.row.name}}</div>
                <div style="margin: 0 8px;color: #777E90">|</div>
                <div style="font-size: 14px;color: #B1B5C3;font-weight: bold;">{{scope.row.symbol}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="price_usd"
              label="最新价格(美元)"
              width="140">
          </el-table-column>
          <el-table-column
              prop="price_btc"
              label="最新价格(BTC)"
              width="140">
          </el-table-column>
          <el-table-column
              prop="volume_24h_usd"
              label="24h的成交额"
              width="120">
            <template slot-scope="scope">
              {{formatPriceWithEnglishUnit(scope.row.volume_24h_usd)}}{{computedUnit(scope.row.volume_24h_usd)}}
            </template>
          </el-table-column>
          <el-table-column
              prop="market_cap_usd"
              label="流通市值"
              width="120">
            <template slot-scope="scope">
              {{formatPriceWithEnglishUnit(scope.row.market_cap_usd)}}{{computedUnit(scope.row.market_cap_usd)}}
            </template>
          </el-table-column>
          <el-table-column
              prop="available_supply"
              label="流通数量"
              width="120">
            <template slot-scope="scope">
              {{formatPriceWithEnglishUnit(scope.row.available_supply)}}{{computedUnit(scope.row.available_supply)}}
            </template>
          </el-table-column>
          <el-table-column
              prop="rank"
              label="排名"
              width="80">
            <template slot-scope="scope">
              <div style="width: 30px;height: 30px;border-radius: 50%;border: 1px solid #FFFFFF;text-align: center;line-height: 30px">{{scope.row.rank}}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="total_supply"
              label="总发行量"
              width="120">
            <template slot-scope="scope">
              {{formatPriceWithEnglishUnit(scope.row.total_supply)}}{{computedUnit(scope.row.total_supply)}}
            </template>
          </el-table-column>
          <el-table-column
              prop="max_supply"
              label="最大发行量"
              width="120">
            <template slot-scope="scope">
              {{formatPriceWithEnglishUnit(scope.row.max_supply)}}{{computedUnit(scope.row.max_supply)}}
            </template>
          </el-table-column>
          <el-table-column
              prop="percent_change_1h"
              label="1小时涨跌幅"
              width="120">
            <template slot-scope="scope">
              <div :style="{color:countRate(scope.row.percent_change_1h) }">{{scope.row.percent_change_1h}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="percent_change_24h"
              label="24小时涨跌幅"
              width="120">
            <template slot-scope="scope">
              <div :style="{color:countRate(scope.row.percent_change_24h) }">{{scope.row.percent_change_24h}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="percent_change_7d"
              label="7天涨跌幅"
              width="120">
            <template slot-scope="scope">
              <div :style="{color:countRate(scope.row.percent_change_7d) }">{{scope.row.percent_change_7d}}%</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="last_updated"
              label="行情更新时间"
              width="180">
            <template slot-scope="scope">
              {{ formattedDate(scope.row.last_updated) }}
            </template>
          </el-table-column>
        </el-table>
        <div class="block" style="display: flex;justify-content: right;background: #222630;height: 46px">
          <el-pagination
              @current-change="handleCurrencyCurrentChange"
              :current-page.sync="currencyCurrentPage"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="currencyTotal">
          </el-pagination>
        </div>
      </div>

      <!-- 资讯模块 -->
      <div class="homeContent_title" id="infoModule">资讯模块</div>
      <div class="notice_rank">
        <div style="width: 80%;min-height:400px;margin: 0 auto;">
          <div style="width: 100%;display: flex;justify-content: center">
            <div @click="toInformation(item.noticeId)" class="info_block" v-for="(item,index) in information">
              <div class="info_block_content">
                <img :src="getImgUrl(item.noticeContent)" style="width: 100%">
                <div class="info_block_content_title">{{item.noticeTitle}}</div>
                <div class="info_time">
                  <div style="margin-right: 16px;display: flex">
                    <img src="@/assets/bottom/avatar.svg" style="width: 20px">
                    <div style="margin-top: 3px;margin-left: 10px">{{item.createBy}}</div>
                  </div>
                  <div>{{item.createTime}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 持币地址排行榜 -->
        <div class="homeContent_title">持币地址排行榜</div>
        <div class="coin_rank">
          <div class="coin_rank_child">
            <div style="border-radius: 20px;width: 80%;margin: 40px auto 20px auto;background: #161616">
              <table>
                <tr>
                  <th>ID</th>
                  <th>账号</th>
                  <th>hash钱包</th>
                  <th>积分</th>
                </tr>
                <tr v-for="(item,index) in userInfo" :key="index">
                  <td>
                    <img src="@/assets/1.png" v-if="item.id==='1'" style="width: 30px">
                    <img src="@/assets/2.png" v-else-if="item.id==='2'" style="width: 30px">
                    <img src="@/assets/3.png" v-else-if="item.id==='3'" style="width: 30px">
                    <span v-else>{{ item.userId }}</span>
                  </td>
                  <td>{{ formatAccountNumber(item.account) }}</td>
                  <td>{{ item.hashAddress }}</td>
                  <td>{{ item.integral }}</td>
                </tr>
              </table>
              <div class="block" style="display: flex;justify-content: right;background: #222630;height: 46px">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>

// 在Vue组件中
import axios from 'axios';
import ScrollNumber from "@/components/ScrollNumber.vue";
import {formdDateTime} from "@/utils/utils";
import * as echarts from 'echarts';
import {coinImg, marketImg} from "@/assets/markerImg";
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {ScrollNumber},
  // components: {Bottom, TopHeader},
  data(){
    return{
      currentNumber:0,
      searchContent:'',
      userInfo:[],
      chainList:[],
      marketData:[],
      chainIdList:[],
      interval:'',
      currentPage:1,
      total:0,
      allUserInfo:[],
      testNumber:0,
      loading:false,
      currencyList:[],
      allCurrencyList:[],
      currencyCurrentPage:1,
      currencyTotal:50,
      information:[],
      coinPrice:'',
      chartData:[],
      myChart:null,
      coinImg,
      marketImg,
    }
  },
  methods:{
    getImgUrl(val){
      let parser = new DOMParser();
      let doc = parser.parseFromString(val, "text/html");
      const imgUrls= Array.from(doc.querySelectorAll('img')).map(img => img.src);
      // console.log('img====',imgUrls);
      return imgUrls[0]
    },
    chartXData(){
      return this.chartData.map(item=>item.time)
    },
    chartYData(){
      return this.chartData.map(item=>item.pice)
    },

    formatAccountNumber(val){
      return `${val.slice(0, 3)}****${val.slice(-4)}`;
    },

    initChart() {
      const chartDom = this.$refs.chart;
      this.myChart = echarts.init(chartDom);
    },
    updateChartOptions(){
      const option = {
        title: {
          // text: this.chartData[0].name+'历史价格', // 设置标题文本
          left: 'center', // 标题水平居中
          top: 1, // 标题距离容器顶部的距离
          textStyle: {
            color: '#333', // 标题文字颜色
            fontSize: 26, // 标题文字大小
            fontWeight: 'bold' // 标题文字加粗
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: `
                    <span>时间:&nbsp;&nbsp;&nbsp;<span style="color: #232323">{b}</span></span><br />
                    <div class="chart-data">
                      价格： <span style="color: #232323">{c0}</span>&nbsp;<br />
                    </div>
                `,
        },
        xAxis: {
          type: 'category',
          data: this.chartXData(),
          axisLabel: {
            textStyle: {
              color: '#89868a', // 将 x 轴标签的字体颜色设置为红色
              fontSize: '20px'
            }
          },
          axisLine: {
            lineStyle: {
              type: 'dashed', // 设置为虚线
              color: 'rgba(175,175,175,0.48)', // 分割线颜色
              width: 1 // 分割线宽度
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#89868a', // 将 y 轴标签的字体颜色设置为绿色
              fontSize:'20px'
            }
          },
          splitLine: {
            show: true, // 显示分割线
            lineStyle: {
              type: 'dashed', // 设置为虚线
              color: 'rgba(175,175,175,0.48)', // 分割线颜色
              width: 1 // 分割线宽度
            }
          }
        },
        series: [{
          data: this.chartYData(),
          type: 'line',
          smooth: true,
          areaStyle: {
            // color: '#4423FF' // 淡红色面积区域
            normal: {
              color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1, // 线性渐变的方向，从左上角到右下角
                  [
                    {offset: 0, color: '#4423FF'}, // 起始颜色
                    {offset: 1, color: 'rgba(0,0,0,0)'} // 终止颜色为完全透明
                  ],
                  false // 是否为对象模式
              )
            }
          },
          lineStyle: {
            color: '#583BFF', // 主体线颜色
            width: 4
          },
          itemStyle: {
            color: '#314CFF', // 拐点颜色
            symbolSize: 3,
          },
        }]
      };
      if(this.myChart){
        this.myChart.setOption(option);
      }
    },
    //获取最新币价
    async getPrice(){
      const response = await axios.get('https://super.pei-wang.workers.dev/api/price')
      this.coinPrice=response.data.data
    },
    async getPriceList(){
      const response = await axios.get('https://super.pei-wang.workers.dev/api/priceList')
      this.chartData=response.data.data
      this.updateChartOptions()
    },
    //获取资讯
    async getInformation(){
      const response= await axios.get('https://super.pei-wang.workers.dev/api/noticeList')
      this.information=response.data.data.slice(0,3)
    },
    formattedDate(val) {
      const formatted = formdDateTime(val);
      return formatted
    },
    countRate(val){
      if(val>0&&val!==0){
        return '#008555'
      }else if(val<0&&val!==0){
        return '#d60f2a';
      }else {
        return '#84929c'
      }
    },
    toInformation(val){
      this.$router.push({path:'/information',query:{noticeId:val}})
    },
    indexMethod(index) {
      return index + 1 + (this.currencyCurrentPage - 1) * 10;
    },
    //获取币种排行榜
    async getCurrencyList(){
      const response =await axios.get('https://super.pei-wang.workers.dev/api/ticker');
      this.allCurrencyList=response.data
      this.currencyList=response.data.slice(0,10)
    },
    handleCurrentChange(val) {
      this.userInfo=this.allUserInfo.slice((val-1)*10,val*10)
    },
    handleCurrencyCurrentChange(val) {
      this.currencyList=this.allCurrencyList.slice((val-1)*10,val*10)
    },
    searchAccount(){
      if(this.searchContent){
        this.$router.push({path: '/searchItem',query:{searchContent:this.searchContent}})
      }
      else {
        this.$notify.error({
          title: '错误',
          message: '请输入搜索内容'
        });
      }
    },
    //获取排行榜信息
    async getUserInfo(){
      const response = await axios.get('https://super.pei-wang.workers.dev/v1/integral-top');
      this.allUserInfo=response.data.data
      this.userInfo=response.data.data.slice(0,10)
      this.total=response.data.data.length
    },
    //获取代币列表
    async fetchData() {
      try {
        // this.loading=true
        const response = await axios.get('https://super.pei-wang.workers.dev/api/chain-list');
        response.data.data.sort((a, b) => {
          // 将chainId字符串转换为数字进行比较
          return parseInt(a.chainId, 10) - parseInt(b.chainId, 10);
        });
        this.chainList=response.data.data.slice(1,5);
        this.chainIdList = [];
        for (let i = 0; i < this.chainList.length; i++) {
          this.chainIdList.push(this.chainList[i].chainId)
          await this.getChainList(this.chainIdList[i])
        }
        // this.loading=false
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    //获取代币市场详情
    async getChainList(id) {
      try {
        const response = await axios.get('https://super.pei-wang.workers.dev/api/market-data',{ params: {
            chainId:id,
          }});
        this.marketData.push(response.data.data[0])
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    formatPriceWithEnglishUnit(number) {
      // 定义英文单位及其对应的数值范围
      const units = [
        { value: 1e18, symbol: 'E' }, // Exa (极少使用，视需要添加)
        { value: 1e15, symbol: 'P' }, // Peta
        { value: 1e12, symbol: 'T' }, // Tera
        { value: 1e9, symbol: 'B' },  // Billion
        { value: 1e6, symbol: 'M' },  // Million
        { value: 1e3, symbol: 'K' },  // Thousand
        { value: 1, symbol: '' }      // 没有单位
      ];
      //
      // // 找到合适的单位
      let unit = units.find(u => number >= u.value);
      if (!unit) {
        // 如果没有找到合适的单位（理论上不会发生，除非数字极小或极大），使用默认单位
        unit = units[units.length - 1];
      }

      // 计算并格式化带有单位的数字
      const formattedNumber = (number / unit.value).toFixed(2);

      // 返回格式化的字符串
      return `${formattedNumber}`;
    },
    computedUnit(number){
      const units = [
        { value: 1e18, symbol: 'E',name:'千兆兆' }, // Exa (极少使用，视需要添加)
        { value: 1e15, symbol: 'P',name:'千兆' }, // Peta
        { value: 1e12, symbol: 'T',name:'兆'}, // Tera
        { value: 1e9, symbol: 'B' ,name:'十亿'},  // Billion
        { value: 1e6, symbol: 'M',name:'百万' },  // Million
        { value: 1e3, symbol: 'K',name:'千' },  // Thousand
        { value: 1, symbol: '' }      // 没有单位
      ];
      // 找到合适的单位
      let unit = units.find(u => number >= u.value);
      if (!unit) {
        // 如果没有找到合适的单位（理论上不会发生，除非数字极小或极大），使用默认单位
        unit = units[units.length - 1];
      }
      return unit.symbol
    },
    toMarket(val){
      this.$router.push({path:'/market', query: {shortName:val}})
    },
  },
  mounted() {
    this.initChart()
    this.getPriceList()
  },
  created() {
    this.fetchData()
    this.getUserInfo()
    this.getCurrencyList()
    this.getInformation()
    this.getPrice()
    this.interval = setInterval(() => {
      this.marketData=[]
      this.fetchData()
      //   5 * 60 * 1000
    },5 * 60 * 1000); // 5分钟，单位为毫秒
  },
  destroyed() {
    clearInterval(this.interval);
  }
}
</script>
<style scoped lang="less">
.number-container {
  overflow: hidden;
  height: 2em; /* 容器高度，根据实际需要调整 */
  line-height: 2em; /* 行高与容器高度相同，确保数字垂直居中 */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.number {
  position: absolute;
  top: 100%; /* 初始位置在容器底部之外 */
  width: 100%;
  text-align: center;
  transition: transform 0.5s ease-in-out; /* 平滑过渡效果 */
}

/* 向上滚动动画 */
@keyframes scrollUp {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

/* 向下滚动动画 */
@keyframes scrollDown {
  from { transform: translateY(0); }
  to { transform: translateY(100%); }
}

.searchPanel{
  width: 100%;
  height: 500px;
  //background: white;
  background-image: url(../assets/bg.png);
  //background-image: url(../assets/bg.svg);
  background-position:  center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  &_price {
    width: 70%;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    color: white;
    height: 100px;
    //background: red;
    line-height: 100px;
    text-align: right;
  }
  &_title {
    margin-top: 120px;
    font-weight: bold;
    font-size: 80px;
    color: white;
    height: 150px;
    //background: #409eff;
    line-height: 150px;
    text-align: center;
  }
  &_input {
    height: 58px;
    padding: 0 22px ;
    border-radius: 3em;
    border: 1px solid #ffffff;
    width: 80%;
    margin: 50px auto;
    outline: none;
    font-size: 20px;
    font-weight: 500;
    color: #B1B5C3;
    background: rgba(255, 255, 255, 0.2);
  }
  &_input::placeholder {
    color: #B1B5C3; /* 修改placeholder文本颜色 */
    font-size: 20px;
  }

  &_input:focus {
    border-color: #a496fd;
  }
  &_imgBlock {
    position: absolute;
    right:7.68%;
    top: 54.5px;
    width: 90px;
    height: 49px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 1.8em;
    cursor: pointer;
    background-image: linear-gradient(to bottom right, #583bff, #836DFF);
  }
  &_img {
    width: 30px;
    margin-top:10px;
    margin-bottom: auto;
    // font-size: 32px;
    // stroke-width:8;

  }
  &_indexInfo {
    max-width: inherit;
    //margin-top: -10px;
    color: #B1B5C3;
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
    font-weight: 400;
    justify-content: center;
    line-height: 24px;
    text-align: center;
    p{
      // text-align: center;
      font-weight: 500;
    }
    a{
      color: #4b28e5;
      margin-left: 16px;
      margin-top: 24px;
      text-decoration: none;
    }
  }
}
.homeContent{
  width: 100%;
  margin: 0 auto;
  &_title{
    font-weight: bold;
    height: 120px;
    margin: 60px 0 0 0;
    line-height: 120px;
    font-size: 40px;
    text-align: center;
    color: #ffffff;
  }
  &_panel {
    width: 82%;
    //height: 276px;
    z-index: 999;
    position: relative;
    margin: 30px auto;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    background: #18191d;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: space-between;
    border: 1px solid #23262F;


    &_small {
      padding: 20px;
      width: 21%;
      cursor: pointer;
      transition: all .3s ease;
      position: relative;
      border-radius: 8px;
      //border: 1px solid rgba(255, 255, 255, 0.05);

      &_title {
        font-size: 18px;
        color: #FFFFFF;
        margin: 0 0 14px 0;
        display: flex;;
        justify-content: space-between;
      }
    }

    &_small:hover {
      //box-shadow: 0 0 10px 5px rgba(121, 91, 255, 0.5);
      //background: rgba(255, 255, 255, 0.91);
      background: #222630;
    }

    &_small:hover .homeContent_panel_small_title {
      color: #ffffff;
    }
  }
  &_link{
    width: 100%;
    border-top: 1px solid #23262f;
    //margin-bottom: 20px;
  }
}

.info_block{
  cursor: pointer;
  justify-content: space-evenly;
  padding: 30px;
  width: 30%;
  &_imgBlock {
    height: 112px;
    width: 150px;
    display: block;
    overflow: hidden;
    margin-right: 16px;
    img{
      transition: all .5s;
    }
  }
  &_content{
    //width: 900px;
    &_title {
      color: #FFFFFF;
      font-size: 24px;
      font-weight: 700;
      height: 75px;
      margin-bottom: 10px;
      overflow: hidden;
      line-height: 36px;
      margin-top: 18px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    &_info{
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #455058;
      overflow:hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
  .info_time {
    display: flex;
    margin-top: 20px;
    color: #777E90;
    font-size: 14px;
    justify-content: space-between;
  }
}
.info_block:hover{
  //box-shadow: 0 5px 24px 0 rgba(159,169,176,.15),0 5px 24px 0 rgba(159,169,176,.15);
}

//.info_block:hover img{
//  transform:scale(1.1)
//}

.info_block:hover .info_block_content_title{
  color: #4b28e5;
}

table {
  border-collapse: collapse; /* 合并相邻边框 */
  width: 100%; /* 表格宽度设置为100% */
  //background: #f1f2f6;
  padding: 20px;
  color: white;
  box-sizing: border-box;
}

td{
  font-size: 16px;
}

th{
  text-align: left;
  font-size: 18px;
  background: #222630;
}

td:first-child{
  width: 100px;
  text-align: center;
  padding: 0 0 0 10px;
}

th:first-child{
  text-align: center;
  padding: 0 0 0 10px;
}

td:nth-child(4){
  width: 90px;
  color: #8570FF;
}


th,td{
  border-bottom: 1px solid #23262F;
  border-collapse: collapse;
  height: 50px;
  line-height: 50px;
}
.echarts_content{
  background-image: url(../assets/bg/bg_1.png);
  //background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-size: 80px;
  background-position: right;
}
.coin_rank{
  width: 100%;
  background-image: url(../assets/bg/bg_2.png);
  //background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-size: 40%;
  background-position: left center;
  &_child{
    width: 100%;
    background-image: url(../assets/bg/bg_3.png);
    //background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-size: 15%;
    background-position: right center;
  }
}
.notice_rank{
  width: 100%;
  background-image: url(../assets/bg/bg_4.png);
  //background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-size: 80px;
  background-position: left center;
}
</style>

