export function formdDateTime(timeVal) {
    // 假设如果timeVal是字符串且长度大于10，则它是毫秒级的时间戳；否则，假设它是秒级的时间戳
    let date;
    if (typeof timeVal === 'string' && timeVal.length > 10) {
        date = new Date(parseInt(timeVal, 10)); // 将字符串转换为整数，然后创建Date对象
    } else {
        date = new Date(timeVal * 1000); // 如果是秒级时间戳，则乘以1000转换为毫秒
    }

    // 使用模板字符串和padStart来简化日期和时间的格式化
    const Y = date.getFullYear();
    const M = (date.getMonth() + 1).toString().padStart(2, '0');
    const D = date.getDate().toString().padStart(2, '0');
    const h = date.getHours().toString().padStart(2, '0');
    const m = date.getMinutes().toString().padStart(2, '0');
    const s = date.getSeconds().toString().padStart(2, '0');

    // 拼接并返回格式化后的日期和时间字符串
    return `${Y}-${M}-${D} ${h}:${m}:${s}`;
}

export function formdDate(timeVal) {
    // 假设如果timeVal是字符串且长度大于10，则它是毫秒级的时间戳；否则，假设它是秒级的时间戳
    let date;
    if (typeof timeVal === 'string' && timeVal.length > 10) {
        date = new Date(parseInt(timeVal, 10)); // 将字符串转换为整数，然后创建Date对象
    } else {
        date = new Date(timeVal * 1000); // 如果是秒级时间戳，则乘以1000转换为毫秒
    }

    // 使用模板字符串和padStart来简化日期和时间的格式化
    const Y = date.getFullYear();
    const M = (date.getMonth() + 1).toString().padStart(2, '0');
    const D = date.getDate().toString().padStart(2, '0');

    // 拼接并返回格式化后的日期和时间字符串
    return `${Y}-${M}-${D}`;
}