<template>
<div class="bottom">
  <div style="margin: 0 0 0 10px"><img src="@/assets/logo.png" style="height: 58px;"></div>
  <div class="bottom_headLine" @click="expand(0)">
    <div>Tuyên bố miễn trách nhiệm</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="bottom_center" v-if="declare">
    <div class="bottom_center_smallTitle">
      <div style="width: 100%;margin: 0 auto;line-height: 25px;">
        <img src="@/assets/danger.svg" style="width: 12px;margin-right: 2px;margin-top: 4px">
        <span style="color: #d60f2a">Tuyên bố miễn trách nhiệm：</span>Tất cả nội dung được cung cấp trên trang web này,
        siêu liên kết, ứng dụng liên quan, diễn đàn, blog và các tài khoản truyền thông khác đều có nguồn gốc
        từ nền tảng của bên thứ ba。Chúng tôi không đảm bảodưới bất kỳ hình thức nào về trang web và nội dung
        của nó，Tất cả dữ liệu và thông tin trên trang web chỉ nhằm mục đích tìm hiểu và nghiên cứu của người
        dùng,Không cấu thành bất kỳ khoản đầu tư nào、Đề xuất và bằng chứng của pháp luật và các lĩnh vực liên
        quan。Bạn nên sử dụng dữ liệu và nội dung một cách thận trọng và tự chịu mọi rủi ro phát sinh。Chúng tôi
        khuyến khíchTiến hành nghiên cứu của riêng bạn về nội dung、Xem xét, phân tích và xác minh。
      </div>
    </div>
  </div>
  <div class="bottom_headLine" @click="expand(1)">
    <div>Liên hệ với chúng tôi</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="bottom_left" v-if="linkOur">
    <div style="margin: 25px auto;">
      <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-phone-outline" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left: 15px;margin-top: 3px">13232323232</div></div>
      <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-message" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left:15px;margin-top: 3px">asfg@email.com</div></div>
      <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-chat-dot-square" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left:15px;margin-top: 3px">@13232323232</div></div>
      <div style="display: flex;"><i class="el-icon-location" style="color: #777E90;font-size: 22px"></i><div style="font-size: 16px;color: #B1B5C3;margin-left:15px;margin-top: 3px">北京市xx区xx</div></div>
    </div>
    <div class="bottom_center_smallTitle">Dịch vụ khách hàng chính thức</div>
    <div class="bottom_center_smallTitle">lợi nhuận giao dịch hạng mục</div>
    <div class="bottom_center_smallTitle">hợp tác quảng cáo</div>
  </div>
  <div class="bottom_headLine" @click="expand(2)">
    <div>Hỗ trợ</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="bottom_center" v-if="support">
    <div class="bottom_center_smallTitle">nộp đơn Ứng dụng truyền thông</div>
    <div class="bottom_center_smallTitle">Nộp đơn dự án hạng mục</div>
    <div class="bottom_center_smallTitle">Nộp đơn tỉ lệ hiệu quả giáo dục</div>
  </div>
  <div class="bottom_headLine" @click="expand(3)">
    <div>Dịch vụ người dùng</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="bottom_center" v-if="servece">
    <div class="bottom_center_smallTitle">Câu hỏi thường gặp</div>
    <div class="bottom_center_smallTitle">phản hồi, kênh xác</div>
    <div class="bottom_center_smallTitle">minh chính thức</div>
  </div>
  <div class="bottom_headLine" @click="expand(4)">
    <div>Nhiều nội dung hơn</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="bottom_center" v-if="getMore">
    <div class="bottom_center_smallTitle">giới thiệu về chúng tôi</div>
    <div class="bottom_center_smallTitle">tuyên bố miễn trách nhiệm</div>
    <div class="bottom_center_smallTitle">Truy cập phiên bản di động</div>
  </div>
  <div class="bottom_headLine" @click="expand(5)">
    <div>Link tải về</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="bottom_right" v-if="download">
    <div>
      <div class="download">
        <img src="@/assets/bottom/IOS.svg">
        <div class="download_title">app store tải về</div>
      </div>
      <div class="download">
        <img src="@/assets/bottom/anz.svg">
        <div class="download_title">android</div>
      </div>
      <div class="download">
        <img src="@/assets/bottom/win.svg">
        <div class="download_title">Windows</div>
      </div>
      <div class="download">
        <img src="@/assets/bottom/mac.svg">
        <div class="download_title">Mac</div>
      </div>
    </div>
  </div>
  <div class="bottom_headLine" @click="expand(6)" style="margin: 30px auto 0 auto;padding: 0 0 20px 0">
    <div>Liên kết thân thiện</div>
    <i class="el-icon-arrow-down"></i>
  </div>
  <div class="cooperate_content" v-if="friendlyLink">
    <div class="link"><a href="http://www.oicq88.com/" target="_blank" class="item">tên mạng</a></div>
    <div class="link"><a href="https://apr999.com/" target="_blank" class="item">defi So sánh thu nhập và khai thác</a></div>
    <div class="link"> <a href="http://www.qqbiaoqing.com/" target="_blank" class="item">BIỂU TƯỢNG CẢM XÚC</a></div>
    <div class="link"><a href="http://www.ip138.com/" target="_blank" class="item">truy vấn IP</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/ethereum/" target="_blank" class="item">Ethereum</a></div>
    <div class="link"><a href="http://www.liantu.com/" target="_blank" class="item">mã QR</a></div>
    <div class="link"><a href="http://www.shilian.com/" target="_blank" class="item">chuỗi tài chính thế giới</a></div>
    <div class="link"> <a href="https://www.btcfans.com/" target="_blank" class="item">fanfan</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/dogecoin/" target="_blank" class="item">Dogecoin</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/binance-coin/" target="_blank" class="item">BNB</a></div>
    <div class="link"><a href="https://www.feixiaohao.co/currencies/tether/" target="_blank" class="item">Tether</a></div>
    <div class="link"><a href="https://www.fx678.com/" target="_blank" class="item">hội đồng mạng</a></div>
    <div class="link"><a href="https://www.yitaifang.com/" target="_blank" class="item">trình duyệt Ethereum</a></div>
    <div class="link"><a href="https://www.superoneapp.com/" target="_blank" class="item">Đề xuất trang web</a></div>
    <div class="link"><a href="https://www.qklw.com/" target="_blank" class="item">Mạng Blockchain QKLW</a></div>
    <div class="link"> <a href="https://dexnav.com/" target="_blank" class="item">Điều hướng Blockchain</a></div>
  </div>
</div>
</template>

<script>
export default {
  name: "bottomView",
  data(){
    return{
      declare:false,
      linkOur:false,
      support:false,
      servece:false,
      getMore:false,
      download:false,
      friendlyLink:false
    }
  },
  methods:{
    expand(val){
      if(val===0&&this.declare===false){
        this.declare=true
      }else if(val===1&&this.linkOur===false){
        this.linkOur=true
      }else if(val===2&&this.support===false){
        this.support=true
      }else if(val===3&&this.servece===false){
        this.servece=true
      }else if(val===4&&this.getMore===false){
        this.getMore=true
      }else if(val===5&&this.download===false){
        this.download=true
      }else if(val===6&&this.friendlyLink===false){
        this.friendlyLink=true
      }
      else (
          this.declare=false,
          this.linkOur=false,
          this.support=false,
          this.servece=false,
          this.getMore=false,
          this.download=false,
          this.friendlyLink=false
      )
    }
  }
}
</script>

<style scoped lang="less">
.bottom_headLine {
  width: 90%;
  margin: 30px auto;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: white;
}

a{
  color: #B1B5C3;
  display: block;
  text-decoration: none;
}

.bottom {
  height: auto;
  width: 100%;
  border-top: 1px solid #23262f;
}
.link_content {
  width: 90%;
  margin: 0 auto;
}
.bottom_left{
  width: 90%;
  margin: 0 auto;
}
.bottom_center{
  width: 90%;
  margin: 0 auto;
  &_title{
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &_smallTitle{
    font-size: 16px;
    color: #B1B5C3;
    margin-bottom: 15px;
    cursor: pointer;
  }
  &_smallTitle:hover{
    color: #535ce2;
  }
}

.bottom_right{
  width: 90%;
  margin: 0 auto;
}

.download {
  color: #FFFFFF;
  display: flex;
  padding:3px 20px;
  border: 1px solid #FFFFFF;
  border-radius: 2em;
  width: 140px;
  height: 26px;
  line-height: 26px;
  margin-bottom: 24px;
  cursor: pointer;
  justify-content: center;
}

.download img{
   width: 16px;
}

.download_title{
  margin-left: 8px;
  font-size: 14px;
}

.cooperate_content {
  width: 90%;
  margin: 0 auto;
}

.link{
  line-height: 45px;
  cursor: pointer;
}
</style>