<template>
  <div style="background-color:#2f2f2f; position: relative">
    <div v-if="screenWidth<600&&show" style="width: 100%;background: rgb(255,255,255);height: 97.3%;position: absolute;top: 0">
      <div style="width: 95%;margin: 0 auto;">
        <div style="display: flex;justify-content: space-between;">
          <img src="@/assets/logo.png" style="height: 50px;margin-top: 10px">
          <img src="@/assets/cancel.svg" style="width: 20px;text-align: right;cursor: pointer;margin-right: 6px" @click="cancel()">
        </div>
        <div style="width: 100%;">
          <div @click="toPage('1')" :class="activeIndex==='1' ? 'headerTitle_active' : 'headerTitle'">
            <div v-if="lang==='简体中文'">首页</div>
            <div v-if="lang==='TIẾNG VIỆT'">TRANG CHỦ</div>
            <div><img src="@/assets/row_active.svg" style="width: 25px;margin-top: 12px"></div>
<!--            <div><img src="@/assets/row.svg" style="width: 30px"></div>-->
          </div>
          <div @click="toPage('2')" :class="activeIndex==='2' ? 'headerTitle_active' : 'headerTitle'">
            <div v-if="lang==='简体中文'">关于我们</div>
            <div v-if="lang==='TIẾNG VIỆT'">VỀ CHÚNG TÔI</div>
            <div><img src="@/assets/row_active.svg" style="width: 25px;margin-top: 12px"></div>
          </div>
<!--          <div @click="toPage('3')" :class="activeIndex==='3' ? 'headerTitle_active' : 'headerTitle'">-->
<!--            <div>项目介绍</div>-->
<!--            <div><img src="@/assets/row_active.svg" style="width: 25px;margin-top: 12px"></div>-->
<!--          </div>-->
<!--          <div @click="toPage('4')" :class="activeIndex==='4' ? 'headerTitle_active' : 'headerTitle'">-->
<!--            <div>白皮书</div>-->
<!--            <div><img src="@/assets/row_active.svg" style="width: 25px;margin-top: 12px"></div>-->
<!--          </div>-->
          <div @click="toPage('5')" :class="activeIndex==='5' ? 'headerTitle_active' : 'headerTitle'">
            <div v-if="lang==='简体中文'">新币上市</div>
            <div v-if="lang==='TIẾNG VIỆT'">THỊ TRƯỜNG TIỀN điện tử MỚI </div>
            <div><img src="@/assets/row_active.svg" style="width: 25px;margin-top: 12px"></div>
          </div>
        </div>
        <div style="width: 80%;display: flex;justify-content: center;;margin: 10px auto 0 auto" v-if="$route.path!=='/information'">
          <el-dropdown  @command="changeLang">
            <el-button class="lang" round style="margin-top: 10px;height: 40px;min-width: 120px" icon="el-icon-sort">{{lang}}</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="简体中文">简体中文</el-dropdown-item>
              <el-dropdown-item command="English">English</el-dropdown-item>
              <el-dropdown-item command="TIẾNG VIỆT">TIẾNG VIỆT</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div v-else>
      <top-header></top-header>
      <router-view/>
      <bottom></bottom>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import TopHeader from "@/components/topHeader";
import Bottom from "@/components/bottom";
import bus from "@/bus";
export default {
  name: 'app',
  components: {Bottom, TopHeader},
  data(){
    return{
      screenWidth: null,
      alignCenter:'left',
      show:false,
      activeIndex:'1',
      lang:'简体中文'
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })
    }
    bus.$on('showDialog', data=>{
      this.show=data
    })
    this.lang=this.$store.state.language
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        this.screenWidth=val
      },
      immediate: true,
      deep:true
    },

  },
  methods:{
    changeLang(val){
      console.log(val);
      this.lang=val
      this.$store.commit('setLanguage', val)
      bus.$emit('language',this.lang)
    },
    cancel(){
      this.show=!this.show
      bus.$emit('cancel',this.show)
    },
    toPage(val){
      this.show=false
      bus.$emit('cancel',this.show)
      this.activeIndex=val
      if(val==='1'&& this.$route.path !== '/home'){
        this.$router.push('/home')
      }else if(val==='2'&& this.$route.path !== '/about'){
        this.$router.push('/about')
      }else if(val==='3'&& this.$route.path !== '/introduce'){
        this.$router.push('/introduce')
      }else if(val==='4'&& this.$route.path !== '/whitePaper'){
        this.$router.push('/whitePaper')
      }else if(val==='5'&& this.$route.path !== '/newCurrency'){
        this.$router.push('/newCurrency')
      }else if(val==='6'&& this.$route.path !== '/information'){
        this.$router.push('/information')
      }else if(val==='7'&& this.$route.path !== '/login'){
        this.$router.push('/login')
      }
      sessionStorage.setItem('index',val)
    },
  }
}
</script>
<style lang="less" scoped>
@import "assets/css/base.css";
@import "assets/css/element-css.css";
.topHeader{
  width: 95%;
  margin: 0 auto 2px auto;
  height: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  /*justify-content: space-between;*/
  transition: all 2s ease;
  background: transparent;
}
.headerTitle {
  margin: 0 3%;
  color: #929292;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;;justify-content: space-between;
}

.headerTitle_active{
  height: 50px;
  line-height: 50px;
  margin: 0 3%;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #e7e7e7;
  display: flex;;justify-content: space-between;
}

.headerTitle:hover{
  color: #000;
}
</style>
