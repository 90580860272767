<template>
<div class="bottom">
  <div class="declare">
    <div style="width: 80%;margin: 0 auto;padding: 12px 0;line-height: 25px;">
      <img src="@/assets/danger.svg" style="width: 12px;margin-right: 2px;margin-top: 4px">
      <span style="color: #d60f2a">免责声明：</span>本网站、超链接、相关应用程序、论坛、博客等媒体账户
      以及其他平台提供的所有内容均来源于第三方平台。我们对于网站及其
      内容不作任何类型的保证，网站所有区块链相关数据与资料仅供用户学习
      及研究之用，不构成任何投资、法律等其他领域的建议和依据。您需谨慎
      使用相关数据及内容，并自行承担所带来的一切风险。强烈建议您独自对
      内容进行研究、审查、分析和验证。
    </div>
  </div>
  <div style="border-bottom: 1px solid #23262f">
    <div class="bottom_center">
      <div class="bottom_block">
        <div>
          <div style="margin: 0 0 10px 0"><img src="@/assets/logo.png" style="height: 58px;"></div>
          <div style="color: #FFFFFF;font-size: 16px;">
            如有相关问题
          </div>
          <div style="color: #FFFFFF;font-size: 16px;margin: 10px 0 10px 0">
            请通过下方的邮箱地址联系我们👇
          </div>
          <div style="display: flex;margin: 0 0 15px 0"><i class="el-icon-message" style="color: #777E90;font-size: 30px"></i><div style="font-size: 16px;color: #ffffff;margin-left: 20px;margin-top: 5px">feixiaohaovn@gmail.com</div></div>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;width: 75%;">
        <div class="bottom_block" style="justify-content: right">
          <div>
            <div class="bottom_center_title">小号支持</div>
            <div class="bottom_center_smallTitle" @click="$router.push('/medium')">媒体入驻申请</div>
            <div class="bottom_center_smallTitle" @click="$router.push('/submitProject')">项目收录提交</div>
            <div class="bottom_center_smallTitle" @click="$router.push('/exchange')">交易所收录提交</div>
          </div>
        </div>
        <div class="bottom_block">
          <div>
            <div class="bottom_center_title">用户服务</div>
            <div class="bottom_center_smallTitle" @click="toQuestion()">常见问题</div>
            <div class="bottom_center_smallTitle" @click="toSuggestion()">意见反馈</div>
          </div>
        </div>
        <div class="bottom_block">
          <div>
            <div class="bottom_center_title">更多内容</div>
            <div class="bottom_center_smallTitle" @click="toAbout()">关于我们</div>
            <div class="bottom_center_smallTitle" @click="toDisclaimer()">免责声明</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cooperate_content">
    <div style="display: flex;">
      <div class="link"><a href="http://www.oicq88.com/" target="_blank" class="item">网名</a></div>
      <div class="link"><a href="https://apr999.com/" target="_blank" class="item">defi挖矿收益对比</a></div>
      <div class="link"> <a href="http://www.qqbiaoqing.com/" target="_blank" class="item">表情</a></div>
      <div class="link"><a href="http://www.ip138.com/" target="_blank" class="item">IP查询</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/ethereum/" target="_blank" class="item">以太坊</a></div>
      <div class="link"><a href="http://www.liantu.com/" target="_blank" class="item">二维码</a></div>
      <div class="link"><a href="http://www.shilian.com/" target="_blank" class="item">世链财经</a></div>
      <div class="link"> <a href="https://www.btcfans.com/" target="_blank" class="item">比特范</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/dogecoin/" target="_blank" class="item">狗狗币</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/binance-coin/" target="_blank" class="item">BNB</a></div>
      <div class="link"><a href="https://www.feixiaohao.co/currencies/tether/" target="_blank" class="item">泰达币</a></div>
      <div class="link"><a href="https://www.fx678.com/" target="_blank" class="item">汇通网</a></div>
      <div class="link"><a href="https://www.yitaifang.com/" target="_blank" class="item">以太坊浏览器</a></div>
      <div class="link"><a href="https://www.superoneapp.com/" target="_blank" class="item">网站推荐</a></div>
      <div class="link"><a href="https://www.qklw.com/" target="_blank" class="item">区块链网QKLW</a></div>
      <div class="link"> <a href="https://dexnav.com/" target="_blank" class="item">区块链导航</a></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "bottomView",
  data(){
    return{

    }
  },
  methods:{
    toAbout(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'1'}})
      }else {
        this.$store.commit('setIndex','1')
      }
    },
    toQuestion(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'4'}})
      }else {
        this.$store.commit('setIndex','4')
      }
    },
    toDisclaimer(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'3'}})
      }else {
        this.$store.commit('setIndex','3')
      }
    },
    toSuggestion(){
      if(this.$route.path!=='/about'){
        this.$router.push({path:'/about',query:{aboutIndex:'2'}})
      }else {
        this.$store.commit('setIndex','2')
      }
    }
  }
}
</script>

<style scoped lang="less">
.bottom_block{
  width: 25%;
  display: flex;
  //background: #4b28e5;
  justify-content: right;
}

.bottom_block:first-child{
  justify-content: left;
}


.declare {
  min-height: 70px;
  width: 100%;
  background: #32373b;
  color: #FFFFFF;
  font-size: 14px;
}

a{
  color: #B1B5C3;
  display: block;
  text-decoration: none;
}
.bottom {
  height: 400px;
  width: 100%;
  //background: #938da4;
  border-top: 1px solid #23262f;
}
//.link_content {
//  width: 80%;
//  margin: 0 auto;
//  display: flex;
//}
//.bottom_left{
//  width: 22%;
//  border-right: 1px solid #23262f;
//}
.bottom_center{
  width: 80%;
  //background: #5ebc7c;
  margin: 34px auto;
  display: flex;
  &_title{
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &_smallTitle{
    font-size: 16px;
    color: #B1B5C3;
    margin-bottom: 15px;
    cursor: pointer;
  }
  &_smallTitle:hover{
    color: #535ce2;
  }
}

//.bottom_right{
//  width: 22%;
//  border-left: 1px solid #23262f;
//}

.download {
  color: #FFFFFF;
  display: flex;
  padding:3px 20px;
  border: 1px solid #FFFFFF;
  border-radius: 2em;
  width: 100px;
  height: 26px;
  line-height: 26px;
  margin-bottom: 24px;
  cursor: pointer;

}
.download img{
   width: 16px;
}

.download_title{
  margin-left: 8px;
  font-size: 14px;
}

.cooperate_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
}

.link{
  line-height: 45px;
  margin: 0 10px;
  cursor: pointer;
}
</style>