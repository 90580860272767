<template>
  <div class="topHeader">
    <div style="text-align: right;width: 20%;"><img src="@/assets/logo.png" style="height: 48px;margin-top: 1px"></div>
    <div style="display: flex;width: 50%;margin-left: 3%">
      <div @click="toPage('1')" :class="activeIndex === '1' ? 'headerTitle_active' : 'headerTitle'" v-if="lang==='简体中文'">首页</div>
      <div @click="toPage('1')" :class="activeIndex === '1' ? 'headerTitle_active' : 'headerTitle'" v-if="lang==='TIẾNG VIỆT'">TRANG CHỦ</div>
      <div @click="toPage('2')" :class="activeIndex === '2' ? 'headerTitle_active' : 'headerTitle'" v-if="lang==='简体中文'">关于我们</div>
      <div @click="toPage('2')" :class="activeIndex === '2' ? 'headerTitle_active' : 'headerTitle'" v-if="lang==='TIẾNG VIỆT'">VỀ CHÚNG TÔI</div>
<!--      <div @click="toPage('3')" :class="activeIndex === '3' ? 'headerTitle_active' : 'headerTitle'">项目介绍</div>-->
<!--      <div @click="toPage('4')" :class="activeIndex === '4' ? 'headerTitle_active' : 'headerTitle'">白皮书</div>-->
      <div @click="toPage('5')" :class="activeIndex === '5' ? 'headerTitle_active' : 'headerTitle'" v-if="lang==='简体中文'">新币上市</div>
      <div @click="toPage('5')" :class="activeIndex === '5' ? 'headerTitle_active' : 'headerTitle'" v-if="lang==='TIẾNG VIỆT'">THỊ TRƯỜNG TIỀN điện tử MỚI </div>
    </div>
    <div style="width: 18%;display: flex;justify-content: center;" v-if="$route.path!=='/information'">
      <el-dropdown @command="changeLang" style="">
        <div style="color: white;font-size:16px;cursor: pointer">{{ lang }}<i class="el-icon-sort" style="transform: rotate(90deg);margin-left: 8px"></i></div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="简体中文" >简体中文</el-dropdown-item>
          <el-dropdown-item command="English">English</el-dropdown-item>
          <el-dropdown-item command="TIẾNG VIỆT">TIẾNG VIỆT</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>


export default {
  name: "topHeader",
  data() {
    return {
      activeIndex: '1',
      lang: 'TIẾNG VIỆT'
    }
  },
  created() {
    if (sessionStorage.getItem('index')) {
      this.activeIndex = sessionStorage.getItem('index')
    }
    this.lang=this.$store.state.language
  },
  methods: {
    changeLang(val) {
      console.log(val);
      this.lang = val
      sessionStorage.setItem('lang',val)
      this.$store.commit('setLanguage', val)
    },
    toPage(val) {
      this.activeIndex = val
      if (val === '1' && this.$route.path !== '/home') {
        this.$router.push('/home')
      } else if (val === '2' && this.$route.path !== '/about') {
        this.$router.push('/about')
      } else if (val === '3' && this.$route.path !== '/introduce') {
        this.$router.push('/introduce')
      } else if (val === '4' && this.$route.path !== '/whitePaper') {
        this.$router.push('/whitePaper')
      } else if (val === '5' && this.$route.path !== '/newCurrency') {
        this.$router.push('/newCurrency')
      } else if (val === '6' && this.$route.path !== '/information') {
        this.$router.push('/information')
      } else if (val === '7' && this.$route.path !== '/login') {
        this.$router.push('/login')
      }
      sessionStorage.setItem('index', val)
    }
  }
}
</script>

<style scoped>
.topHeader {
  border-bottom: 1px solid #23262F;
  width: 100%;
  /*margin: 0 auto 2px auto;*/
  height: 50px;
  line-height: 50px;
  display: flex;
  /*justify-content: space-between;*/
  transition: all 2s ease;
  background: transparent;
}

.headerTitle {
  margin: 0 1%;
  color: white;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  cursor: pointer;
  padding: 0 30px;
}

.headerTitle_active {
  margin: 0 1%;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 0 30px;
  height: 100%;
  background: #583bff;
}

.headerTitle:hover {
  /*color: white;*/
  /*background: #583bff;*/
}

.lang.el-button:hover {
  background-color: #ffffff !important;
  color: #303133 !important;
}

.el-button:active {
  color: #303133 !important;
  border-color: #ffffff !important;
  ;
}

a {
  text-decoration: none;
  color: #606266;
}

a:hover{
  color: #66b1ff;
}

/*.el-button:hover{*/
/*  background-color: #409eff !important;*/
/*  color: white !important;*/
/*}*/
/*.loginButton {*/
/*  border-radius: 1.5em;*/
/*  padding: 0 10px;*/
/*  height: 40px;*/
/*  margin-top: 10px;*/
/*  cursor: pointer;*/
/*  border: 1px solid #ffffff;*/
/*  background: #ffffff;*/
/*}*/
</style>