const coinImg = [
    { url: require('@/assets/coinImg/BTC.webp') },
    { url: require('@/assets/coinImg/ETH.webp') },
    { url: require('@/assets/coinImg/LTC.webp') },
    { url: require('@/assets/coinImg/DOGE.webp') },
];

const marketImg = [
    { url: require('@/assets/marketImg/BTC.webp') },
    { url: require('@/assets/marketImg/ETH.webp') },
    { url: require('@/assets/marketImg/USDT.webp') },
    { url: require('@/assets/marketImg/BNB.webp') },
    { url: require('@/assets/marketImg/SOL.webp') },
    { url: require('@/assets/marketImg/USDC.webp') },
    { url: require('@/assets/marketImg/XRP.webp') },
    { url: require('@/assets/marketImg/TON.webp') },
    { url: require('@/assets/marketImg/DOGE.webp') },
    { url: require('@/assets/marketImg/ADA.webp') },
    { url: require('@/assets/marketImg/TRX.webp') },
    { url: require('@/assets/marketImg/WBTC.webp') },
    { url: require('@/assets/marketImg/SHIB.webp') },
    { url: require('@/assets/marketImg/AVAX.webp') },
    { url: require('@/assets/marketImg/OKB.webp') },
    { url: require('@/assets/marketImg/BCH.webp') },
    { url: require('@/assets/marketImg/LINK.webp') },
    { url: require('@/assets/marketImg/DOT.webp') },
    { url: require('@/assets/marketImg/LEO.webp') },
    { url: require('@/assets/marketImg/LTC.webp') },
    { url: require('@/assets/marketImg/FDUSD.webp') },
    { url: require('@/assets/marketImg/NEAR.webp') },
    { url: require('@/assets/marketImg/MATIC.webp') },
    { url: require('@/assets/marketImg/KAS.webp') },
    { url: require('@/assets/marketImg/UNI.webp') },
    { url: require('@/assets/marketImg/PEPE.webp') },
    { url: require('@/assets/marketImg/DAI.webp') },
    { url: require('@/assets/marketImg/ICP.webp') },
    { url: require('@/assets/marketImg/XLM.webp') },
    { url: require('@/assets/marketImg/ETC.webp') },
    { url: require('@/assets/marketImg/XMR.webp') },
    { url: require('@/assets/marketImg/WBETH.webp') },
    { url: require('@/assets/marketImg/APT.webp') },
    { url: require('@/assets/marketImg/CRO.webp') },
    { url: require('@/assets/marketImg/STX.webp') },
    { url: require('@/assets/marketImg/FIL.webp') },
    { url: require('@/assets/marketImg/MNT.webp') },
    { url: require('@/assets/marketImg/ATOM.webp') },
    { url: require('@/assets/marketImg/MKR.webp') },
    { url: require('@/assets/marketImg/TAO.webp') },
    { url: require('@/assets/marketImg/BIT.webp') },
    { url: require('@/assets/marketImg/RNDR.webp') },
    { url: require('@/assets/marketImg/INJ.webp') },
    { url: require('@/assets/marketImg/VET.webp') },
    { url: require('@/assets/marketImg/IMX.webp') },
    { url: require('@/assets/marketImg/AAVE.webp') },
    { url: require('@/assets/marketImg/OP.webp') },
    { url: require('@/assets/marketImg/AR.webp') },
    { url: require('@/assets/marketImg/GRT.webp') },
]

export { coinImg, marketImg };